import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Card, CardContent, Box, CardActions, IconButton } from '@mui/material';
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { alpha, useTheme } from '@mui/material/styles';
import { PromptProvider } from '../../../../providers/menu/prompt-cards-provider';

const HomePrompt = () => {
    const navigate = useNavigate();
    const cardTitles = PromptProvider();
    const theme = useTheme();

    return (
        <Grid container spacing={2} sx={{
            flexWrap: 'nowrap', alignItems: 'center', alignContent: 'center', overflowX: 'scroll', padding: '5px', width: '80%', margin: '0 auto', height: '220px', '&::-webkit-scrollbar': {
                display: 'none'
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none'  // Firefox 
        }}>
            < Grid item xs={12} md={3} sm={4} >
                <Card sx={{ height: 150, width: 200, display: 'flex', alignContent: 'center', flexDirection: 'column', justifyContent: 'center', padding: '5px', backgroundColor: theme.palette.secondary.main, borderTopColor: 'primary', borderTopWidth: '20px', borderRadius: '5px' }}>
                    <CardContent>
                        <Typography variant="inherit" style={{ fontWeight: 'bold', fontSize: '15px', textAlign: 'left', color: theme.palette.background.default, textTransform: 'uppercase', padding: '5px' }} >
                            Some<br />
                            Common<br />
                            Prompts
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', flexWrap: 'nowrap' }}>
                            <IconButton aria-label='Ask This' onClick={(e) => navigate('/chat')}>
                                <ArrowCircleRightOutlined color="primary" style={{ color: theme.palette.background.default }} fontSize='medium' />
                            </IconButton>
                        </Box>
                    </CardActions>
                </Card>
            </Grid >

            {
                cardTitles.map((title, index) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const [isHovered, setIsHovered] = useState(false); // State for hover effect
                    return (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card sx={{ height: isHovered ? 170 : 150, width: 200, display: 'flex', alignContent: 'center', flexDirection: 'column', justifyContent: 'center', padding: '5px', borderTop: isHovered ? '40px solid ' + theme.palette.secondary.main : '16px solid ' + theme.palette.secondary.main, borderRadius: '15px', boxShadow: '1px 1px 10px 5px' + alpha('#000', 0.6), transition: 'transform 0.5s linear, border-top-width 0.5s linear', transform: isHovered ? 'scale(1.05)' : 'scale(1)' }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <CardContent sx={{ padding: isHovered ? '10px' : '5px' }}>
                                    <Typography variant="body1" id={title.id} component="p" style={{ fontSize: isHovered ? '17px' : '15px', color: "primary" }}>
                                        {title.text}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'flex-end' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', flexWrap: 'nowrap', padding: '1px 5px 1px 10px' }}>
                                        <IconButton aria-label='Ask This' onClick={(e) => navigate('/chat')}>
                                            {/* <Typography variant="h6">Ask This</Typography> */}
                                            <ArrowCircleRightOutlined color="primary" fontSize='medium' />
                                        </IconButton>
                                    </Box>
                                </CardActions>
                            </Card>
                        </Grid>
                    );
                })
            }
        </Grid >
    )
};

export default HomePrompt;
