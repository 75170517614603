import {Box, Stack} from "@mui/material";
import ChatMainQuestionItem from "./chat-main-question-item";
import ChatMainAnswerItem from "./chat-main-answer-item";
import ChatMainSuggestions from "./chat-main-suggestions";
import ChatMainPrompt from "./chat-main-prompt";
import {useEffect, useState} from "react";
import {UuidProvider} from "../../../../providers/utils/uuid-provider";

const ChatMain = () => {

    const [responses, setResponses] = useState([]);

    const [suggestions, setSuggestions] = useState([]);

    const [question, setQuestion] = useState("");

    useEffect(() => {
        if (question) {
            const questionId = Math.random() * 1000;

            const updatedResponses = [{id: questionId, question, answer: "..."}, ...responses];

            setResponses(updatedResponses);

            setTimeout(async () => {

                let conversationId = sessionStorage.getItem("chat-id");

                if (!conversationId) {
                    conversationId = await UuidProvider();

                    sessionStorage.setItem("chat-id", conversationId);
                }

                try {
                    const response = await fetch("https://mrsmith.onrender.com/ask", {
                        method: "post",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            orignalQuestionByUser: question,
                            conversationId
                        })
                    });
                    const answer = await response.json();

                    setResponses(updatedResponses.map((item) => {
                        if (item.id === questionId) {
                            item.answer = answer["answer"];
                        }
                        return item;
                    }));

                    setSuggestions(answer["followUpQuestions"])
                } catch (e) {
                    // console.error(e);
                }
            }, 0);
        }
    }, [question]);

    return <Stack
        direction="column"
        sx={{
            paddingX: 1,
            height: "100%"
        }}>
        <Stack direction="column-reverse" sx={{
            height: "inherit",
            overflowY: "auto",
            paddingX: 1,
            paddingBottom: 3
        }}>
            {responses.map((item, index) => {
                return (<Stack
                    key={index}
                    spacing={2}
                    marginTop={8}>
                    <ChatMainQuestionItem question={item.question}/>
                    <ChatMainAnswerItem answer={item.answer}/>
                </Stack>)
            })}
        </Stack>
        <Box sx={{
            marginRight: {xs: -2, md: 8, xl: 0},
        }}>
            <ChatMainSuggestions suggestions={suggestions} setQuestion={setQuestion}/>
            <ChatMainPrompt setQuestion={setQuestion}/>
        </Box>
    </Stack>;
};

export default ChatMain;
