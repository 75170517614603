import {Button, IconButton, Stack} from "@mui/material";
import {Add, CloseOutlined} from "@mui/icons-material";


const ChatRecentHeader = ({onToggleSideBar, headerRef}) => {

    return <Stack
        ref={headerRef}
        direction="column"
        alignItems="flex-start">
        <IconButton size="large" onClick={onToggleSideBar}>
            <CloseOutlined size="large"/>
        </IconButton>
        <Stack
            flexDirection="row"
            width="100%"
            marginY={{xs: 1, sm: 2, md: 8}}>
            <Button
                variant="outlined"
                startIcon={<Add/>}
                color="primary"
                align="left"
                sx={{
                    justifyContent: "flex-start",
                    minWidth: "80%",
                    borderRadius: "25px"
                }}>
                New Chat
            </Button>
        </Stack>
    </Stack>;
};

export default ChatRecentHeader;
