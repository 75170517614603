const PromptProvider = () => {
    return [
        {
            id: 1,
            text: "What does Dicomm group do?",
        },
        {
            id: 2,
            text: "Do you do ecommerce projects?",
        },
        {
            id: 3,
            text: "How much does a 360 campaign cost?",
        },
        {
            id: 4,
            text: "What does Dicomm group do?",
        },
        {
            id: 5,
            text: "Do you do ecommerce projects?",
        },
        {
            id: 6,
            text: "How much does a 360 campaign cost?",
        },
        {
            id: 7,
            text: "What does Dicomm group do?",
        },
        {
            id: 8,
            text: "Do you do ecommerce projects?",
        },
        {
            id: 9,
            text: "How much does a 360 campaign cost?",
        },
        {
            id: 10,
            text: "What does Dicomm group do?",
        },
    ];
};

export { PromptProvider };
