import React from 'react';
import {Stack} from "@mui/material";
import HomeBanner from "../../layout/pages/home/banner";
import HomePrompt from "../../layout/pages/home/prompt";
import HomeChat from "../../layout/pages/home/chat-input";


const PageHome = () => {
    return (<Stack>
        <HomeBanner/>
        <HomePrompt/>
        <HomeChat />
    </Stack>);
};

export default PageHome;
