const MenuFooterProvider = () => {
    return [
        {
            title: "Privacy Policy",
            path: "/privacy-policy",
        },
        {
            title: "Dicomm McCann",
            path: "/dicomm-mccann",
        },
        {
            title: "Total Media",
            path: "/total-media",
        },
        {
            title: "Dynaton",
            path: "/dynaton",
        },
        {
            title: "Brave Studio",
            path: "/brave-studio",
        },
        {
            title: "Blog",
            path: "/blog",
        },
        {
            title: "English (English)",
            path: "#",
        }
    ];
}

export {MenuFooterProvider};
