import { Stack, Typography } from "@mui/material";
import { HomeTitleSubtitle } from "../../../../providers/menu/home-titles-subtitles-provider";
import { useTrail, animated, useSpring, config } from '@react-spring/web';

const titlesSubtitles = HomeTitleSubtitle();

const HomeBannerCenter = () => {
    const trail = useTrail(titlesSubtitles.length, {
        from: { opacity: 0, transform: 'scale(0.8)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: { mass: 1, tension: 280, friction: 60 },
        delay: index => index * 50 + 10, // Delay the animation slightly
    });

    const textAnimation = useSpring({
        from: { width: '0%' },
        to: { width: '100%' },
        config: config.molasses, // Adjust the animation config as needed
        delay: 300, // Delay after the initial popup
    });

    return (
        <Stack>
            {trail.map((style, index) => (
                <animated.div key={titlesSubtitles[index].id} style={style}>
                    <Typography
                        variant="h2"
                        id={titlesSubtitles[index].id}
                        color="tertiary"    
                        sx={{
                            fontWeight: '600',
                            fontSize: {
                                xs: '1.5rem',
                                sm: '2rem',
                                md: '2.5rem',
                                lg: '3rem',
                                xl: '3.5rem',
                            },
                            textAlign: 'center',
                            overflow: 'hidden',
                            whiteSpace: 'wrap'
                        }}
                    >
                        <animated.span style={textAnimation}>
                            {titlesSubtitles[index].title}
                        </animated.span>
                    </Typography>
                    <Typography
                        variant="h"
                        id={titlesSubtitles[index].id}
                        color="primary"
                        sx={{
                            m: '50px auto',
                            display: 'block',
                            textAlign: 'center',
                            width: '70%',
                            fontSize: { lg: '1.4rem', xl: '1.3rem', sm: '1.2rem', xs: '1.1rem' },
                        }}
                    >
                        {titlesSubtitles[index].subtitle}
                    </Typography>
                </animated.div>
    ))
}
        </Stack >
    );
};

export default HomeBannerCenter;
