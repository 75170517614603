import {Icon, Stack, Typography} from "@mui/material";
import {Person} from "@mui/icons-material";

const ChatMainAnswerItem = ({answer}) => {

    return <Stack
        direction="column">
        <Stack
            gap={1}
            direction="row"
            alignItems="flex-start">
            <Icon fontSize="large">
                <Person fontSize="large" color="primary"/>
            </Icon>
            <Typography
                marginY="auto"
                variant="subtitle1"
                align="left"
                color="primary"
                sx={{
                    fontWeight: "bold"
                }}>
                Answer
            </Typography>
        </Stack>
        <Stack marginLeft={5} marginTop={1}>
            <Typography align="left" color="primary">
                {answer}
            </Typography>
        </Stack>
    </Stack>;
};

export default ChatMainAnswerItem;
