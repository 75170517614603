import HomeBannerStart from "./home-banner-start";
import HomeBannerCenter from "./home-banner-center";
import HomeBannerEnd from "./home-banner-end";
import {Grid} from "@mui/material";


const HomeBanner = () => {
    return (
        <Grid container spacing={2} columns={24} sx={{mt: 8, mb: 8}}>
            <Grid item xs={3}>
                <HomeBannerStart/>
            </Grid>
            <Grid item xs={18}>
                <HomeBannerCenter/>
            </Grid>
            <Grid item xs={3}>
                <HomeBannerEnd/>
            </Grid>
        </Grid>
    );
};

export default HomeBanner;
