const MenuHeaderProvider = () => {
    return [
        {
            title: "Home",
            path: "/",
        },
        {
            title: "About Us",
            path: "/about-us",
        },
        {
            title: "How To Get Started",
            path: "/how-to-get-started",
        },
        {
            title: "Blog",
            path: "/blog",
        },
        {
            title: "Contact Us",
            path: "/contact-us",
        },
    ];
}

export {MenuHeaderProvider};
