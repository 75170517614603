import {Icon, Stack, Typography} from "@mui/material";
import {ChatBubbleOutline} from "@mui/icons-material";
import {LoremIpsum} from "lorem-ipsum";
import {range} from "lodash";
import {useLayoutEffect, useState} from "react";

const ChatRecentList = ({headerHeight, headerRef}) => {

    const lorem = new LoremIpsum({
        sentencesPerParagraph: {
            max: 8,
            min: 4
        },
        wordsPerSentence: {
            max: 5,
            min: 4
        }
    });

    const recent = range(1, 100);

    const [panelHeaderHeight, setPanelHeaderHeight] = useState(0); // magic number?

    const onResize = () => {
        setPanelHeaderHeight(headerRef.current.offsetHeight + headerHeight);
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [headerRef, headerHeight, panelHeaderHeight]);

    useLayoutEffect(onResize, [headerRef, headerHeight, panelHeaderHeight]);

    return <Stack spacing={1}>
        <Typography
            variant="subtitle1"
            color="primary"
            align="left"
            sx={{
                fontWeight: "bold"
            }}>
            Recent
        </Typography>
        <Stack
            gap={1}
            sx={{
                height: `calc(100vh - ${panelHeaderHeight}px - 5rem)`,
                overflowY: "auto"
            }}>
            {recent.map((item, index) => {
                return <Stack key={index} direction="row" spacing={1}>
                    <Icon>
                        <ChatBubbleOutline fontSize="small" color="primary"/>
                    </Icon>
                    <Typography
                        align="left"
                        color="primary"
                        noWrap
                    >
                        {lorem.generateSentences(1)}
                    </Typography>
                </Stack>;
            })}
        </Stack>
    </Stack>;
};

export default ChatRecentList;
