import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, InputAdornment, IconButton, Stack } from '@mui/material';
import {alpha, useTheme} from '@mui/material/styles';
import ArrowCircleRightOutlined from '@mui/icons-material/ArrowCircleRightOutlined';

function ChatInput() {
  const theme = useTheme();
  const [isCursorVisible, setIsCursorVisible] = useState(true);
  const [isHovered, setIsHovered] = useState(false); // State for hover effect
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsCursorVisible((prev) => !prev);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Stack
    direction={{xs: 'column', sm: 'row'}}
    alignItems="left"
    sx={{
      p: 3, // Padding
      // display: 'flex',
      // alignItems: 'left',
      borderBottom: '7px solid ' + theme.palette.primary.main, // Blue line at the bottom
      borderImage: 'linear-gradient(to right, ' + theme.palette.primary.main + ' 60%, ' + theme.palette.primary.main + ' 40%) 2 round',
      borderRadius: 2,
      width: '70%',
      margin: '50px auto',
      boxShadow: '1px 1px 500px ' + alpha(theme.palette.primary.main, 0.2),
      backgroundColor: theme.palette.background.paper,
    }}>
      <Box sx={{ flexGrow: 1,backgroundColor: theme.palette.background.paper }}>
        <Typography variant="body1" sx={{ justifyContent: 'left', textAlign: 'left', fontSize: '17px', fontWeight: 'bold', color: theme.palette.secondary.main, backgroundColor: theme.palette.background.paper }}>
          How can I help you today?
        </Typography>
        <TextField
          fullWidth
          placeholder="Ask anything..."
          variant="standard"
          sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.secondary.main }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {isCursorVisible && <span style={{ fontSize: '1.2em' }}>|</span>} {/* Blinking cursor */}
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ ml: 2 }}> {/* Margin left for spacing */}
        <IconButton sx={{ backgroundColor: '' }} onClick={(e) => navigate('/chat')}>
          <ArrowCircleRightOutlined sx={{ color: isHovered ? theme.palette.secondary.main : theme.palette.background.paper }} fontSize='large' onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)} />
        </IconButton>
      </Box>
    </Stack>
  );
}

export default ChatInput;