import {Box, IconButton, Link, Paper, Stack, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {MenuFooterProvider} from "../../providers/menu/menu-footer-provider";
import {DateTime} from "luxon";
import {Google as GoogleIcon} from "@mui/icons-material";
import {useLayoutEffect, useState} from "react";
import AuthenticationDialog from "../dialog/authentication-dialog";

const Footer = ({headerRef}) => {

    const menuFooter = MenuFooterProvider();

    const [dialogOpen, setDialogOpen] = useState(false);

    const onToggleDialog = () => {
        setDialogOpen(!dialogOpen);
    }

    const [headerHeight, setHeaderHeight] = useState(96); // magic number?

    const onResize = () => {
        setHeaderHeight(headerRef.current.offsetHeight);
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [headerRef, headerHeight]);

    useLayoutEffect(onResize, [headerRef, headerHeight]);

    return <>
        <AuthenticationDialog dialogOpen={dialogOpen} onToggleDialog={onToggleDialog}/>
        <Box>
            <Stack
                flexDirection="column"
                gap={2}
                alignItems="center"
                py={2}
                justifyContent="center"
                marginTop={7}>
                <Stack
                    flexDirection="row"
                    flexWrap="wrap"
                    gap={3}
                    alignItems="center"
                    justifyContent="center">
                    {menuFooter.map((link, index) => {
                        return <Link component={RouterLink}
                                     key={index}
                                     to={link.path}
                                     sx={{
                                         textDecoration: 'none',
                                         "&:hover": {
                                             textDecoration: 'underline'
                                         }
                                     }}>
                            <Typography
                                variant="subtitle2"
                                color="primary"
                                sx={{
                                    fontWeight: "bold",
                                }}>
                                {link.title}
                            </Typography>
                        </Link>
                    })}
                </Stack>
                <Typography variant="body2" color="primary">
                    Dicomm Group Website © {DateTime.now().year}
                </Typography>
            </Stack>
            <Paper
                sx={[
                    (theme) => ({
                        position: "fixed",
                        top: {xs: `calc(${headerHeight}px + 80px)`, md: "initial"}, // magic number?
                        bottom: {xs: "initial", md: 0},
                        right: 0,
                        marginBottom: {xs: "initial", md: 3},
                        marginRight: {xs: "initial", md: 3},
                        borderBottomRightRadius: {xs: "initial", md: "50%"},
                        borderTopRightRadius: {xs: "initial", md: "50%"},
                        borderBottomLeftRadius: {xs: "25%", md: "50%"},
                        borderTopLeftRadius: {xs: "25%", md: "50%"},
                        backgroundImage: "linear-gradient(to right, " + theme.palette.tertiary.main + ", " + theme.palette.secondary.main + ")",
                        "&:hover": {
                            backgroundImage: "linear-gradient(to right, " + theme.palette.tertiary.dark + ", " + theme.palette.secondary.dark + ")",
                        }
                    })
                ]}>
                <IconButton
                    onClick={onToggleDialog}
                    size="large">
                    <GoogleIcon size="large" sx={[
                        (theme) => ({
                            color: theme.palette.secondary.contrastText,
                        })
                    ]}/>
                </IconButton>
            </Paper>
        </Box>
    </>;
};

export default Footer;
