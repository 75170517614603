import {Container, Grid2 as Grid} from "@mui/material";
import ChatRecent from "../../layout/pages/chat/recent";
import ChatMain from "../../layout/pages/chat/main";
import {useEffect, useLayoutEffect, useState} from "react";


const PageChat = ({headerRef}) => {

    if (localStorage.getItem("sideBarOpen") === null) {
        localStorage.setItem("sideBarOpen", "true");
    }

    const sideBarOpen = localStorage.getItem("sideBarOpen") === "true";

    const [isSideBarOpen, setSideBarOpen] = useState(sideBarOpen);

    const onToggleSideBar = (event) => {
        setSideBarOpen(!isSideBarOpen)
    }

    useEffect(() => {
        localStorage.setItem("sideBarOpen", isSideBarOpen.toString());
    }, [isSideBarOpen]);

    const [headerHeight, setHeaderHeight] = useState(96); // magic number?

    const onResize = () => {
        setHeaderHeight(headerRef.current.offsetHeight);
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [headerRef, headerHeight]);

    useLayoutEffect(onResize, [headerRef, headerHeight]);

    return <Grid
        container
        spacing={1}
        columns={24}
        direction={{xs: "row-reverse", md: "row"}}
        sx={{
            flexGrow: 1,
            height: `calc(100vh - ${headerHeight}px)`,
            "& > *": {
                transition: "width .25s ease-out"
            }
        }}>
        <Grid
            size={{
                xs: isSideBarOpen ? 0 : 1,
                md: isSideBarOpen ? 8 : 1,
                lg: isSideBarOpen ? 6 : 1,
                xl: isSideBarOpen ? 4 : 1
            }}>
            <ChatRecent isSideBarOpen={isSideBarOpen} onToggleSideBar={onToggleSideBar} headerHeight={headerHeight}/>
        </Grid>
        <Grid
            component={Container}
            size="grow"
            sx={{
                flexGrow: 1,
                height: "100%"
            }}>
            <ChatMain/>
        </Grid>
    </Grid>;
};

export default PageChat;
