import * as React from "react";
import {Button, FormControlLabel, Link, Switch, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

export const HeaderGetStartedButton = ({sx = {}}) => {
    return <Button
        variant="contained"
        color="secondary"
        component={Link}
        to="/how-to-get-started"
        sx={[
            (theme) => ({
                borderRadius: "25px",
                backgroundImage: "linear-gradient(to right, " + theme.palette.tertiary.main + ", " + theme.palette.secondary.main + ")",
                "&:hover": {
                    backgroundImage: "linear-gradient(to right, " + theme.palette.tertiary.dark + ", " + theme.palette.secondary.dark + ")",
                },
                whiteSpace: "nowrap",
                ...sx
            })
        ]}>
        Get Started
    </Button>
}

export const HeaderDarkModeButton = ({sx = {}, mode, onToggleTheme}) => {
    return <FormControlLabel
        control={
            <Switch color="primary" checked={mode === "dark"} onChange={onToggleTheme}/>
        }
        sx={{...sx}}
        label={"Dark Mode " + (mode === "dark" ? "Off" : "On")}
        labelPlacement="start"
        slotProps={{
            typography: {
                color: "primary",
                variant: "body2",
                whiteSpace: "nowrap"
            }
        }}
    />
}

export const HeaderLinkButton = ({link}) => {
    return <Link component={RouterLink}
                 to={link.path}
                 sx={{
                     textDecoration: 'none',
                     "&:hover": {
                         textDecoration: 'underline'
                     }
                 }}>
        <Typography
            variant="subtitle2"
            color="primary"
            noWrap
            sx={{
                fontWeight: "bold",
            }}>
            {link.title}
        </Typography>
    </Link>
}
