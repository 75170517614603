import {Icon, Stack, Typography} from "@mui/material";
import {AccountCircleOutlined} from "@mui/icons-material";

const ChatMainQuestionItem = ({question}) => {

    return <Stack
        direction="row"
        gap={1}
        alignItems="flex-start">
        <Icon fontSize="large">
            <AccountCircleOutlined fontSize="large" color="primary"/>
        </Icon>
        <Typography
            marginY="auto"
            fontSize="large"
            variant="subtitle1"
            align="left"
            color="primary"
            sx={{
                fontWeight: "bold"
            }}>
            {question}
        </Typography>
    </Stack>;
};

export default ChatMainQuestionItem;
